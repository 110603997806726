
export default (url_root) => {
  const defName = "systemManage"
  const ROUTER_DATA_MANAGE = [
    {
      path: "/manage/app",
      name: "appManage",
      meta: {
        title: "Quản lý ứng dụng",
        icon: "ri-settings-3-line",
        name: "Quản lý ứng dụng",
        showMenu: true,
        showNavSubMenu: false,
        belongTo: defName,
        requiresAuth: true,
        cpuiaPermission: true,
      },
      props: {
        nameKCN: "Quản lý ứng dụng",
      },
      component: () =>
        import(
          "./pages/ManageApp.vue"
        ),
    },
  ];

  ROUTER_DATA_MANAGE.map((router) => {
    router.path = url_root ? "/" + url_root + router.path : router.path;
  });
  return ROUTER_DATA_MANAGE;
};
