const ARRROUTER = [
    {
      path: "/",
      name: "AppManageDashboard",
      meta: { title: "Dashboard" },
      component: () => import("./index.vue"),
    },
  ];
  
  export default function (path) {
    return ARRROUTER.map((item) => {
      if (path) item.path = "/" + path + item.path;
      return item;
    });
  }
  