export const portalRouter = "";
export const dataMangeRouter = "manage";
export const systemMangeRouter = "manage/settings";
import APP_STORE_ROUTER from "@/app_store/router.js";
import APP_MANAGE_APP from "@/app_manage_app/router.js";
import APP_DASHBOARD from "@/app_manage_dashboard/router.js"
export default [
    ...APP_STORE_ROUTER(''),
    ...APP_MANAGE_APP(`${dataMangeRouter}/app`),
    ...APP_DASHBOARD("dashboard"),
];
