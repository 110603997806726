const ARRROUTER = [
  {
    path: "/",
    name: "AppStore",
    meta: { title: "Của hàng ứng dụng" },
    component: () => import("@/app_store/pages/store/appStoreList.vue"),
  },
  {
    path: "/:id",
    name: "AppDetailsPublic",
    meta: { title: "Của hàng ứng dụng" },
    component: () => import("@/app_store/pages/AppDetais.vue"),
  },
  {
    path: "/ung-dung",
    name: "UngDung",
    meta: { title: "Của hàng ứng dụng" },
    component: () => import("@/app_store/pages/PageApps.vue"),
  },
];

export default function (path) {
  return ARRROUTER.map((item) => {
    if (path) item.path = "/" + path + item.path;
    return item;
  });
}
