import { fetchGet } from "@/helpers/api/api.js"
import { ref } from "vue"
export const applications = ref([])

export const getListApplications = async () => {
    fetchGet('user-apps/').then(data => {
        if (data) {
            data = data.reverse()
            applications.value = formatListBlockApps(data)
        }
    })
}

const formatListBlockApps = (parentApps) => {
    let results = []
    parentApps.forEach(block => {
        const el = {
            title: block.type.name,
            listPage: formatListApplications(block.apps)
        }
        results.push(el)
    })
    return results
}

const formatListApplications = (apps) => {
    let results = []
    apps.forEach(app => {
        const el = {
            path: app.root_path,
            meta: {
                title: app.name,
                icon: app.icon,
                name: app.name,
            },
            props: {
                nameKCN: app.name
            }
        }
        results.push(el)
    });
    return results
}