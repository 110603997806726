import axios from "axios";

const SERVER_URL = process.env.VUE_APP_SERVER_URL

const TOKENUSER = JSON.parse(localStorage.getItem("auth"));

export const BASE_URL = SERVER_URL;

export default axios.create({
  baseURL: BASE_URL + "/api",
  headers: {
    Authorization: `Bearer ${TOKENUSER}`,
  },
});
